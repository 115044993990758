import request from '@/plugins/axios';
export const getAddr = (data) => {
  return request.post(`/resource/addr/list`, data).then((res) => {
    return res;
  });
};
export const getAddrOld = (data) => {
  return request.post(`/resource/middle/addr/list`, data).then((res) => {
    return res;
  });
};
export const addAddr = (data) => {
  return request.post('/resource/addr/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailAddr = (data) => {
  return request.get('/resource/addr/detail', data).then((res) => {
    return res;
  });
};
export const editAddr = (data) => {
  return request.post('/resource/addr/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteAddr = (data) => {
  return request.post('/resource/addr/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteAddr = (data) => {
  return request.post('/resource/addr/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};

