import request from '@/plugins/axios';
export const getLine = (data) => {
  return request.post(`/resource/line/list`, data).then((res) => {
    return res;
  });
};
export const getLineOld = (data) => {
  return request.post(`/resource/middle/line/list`, data).then((res) => {
    return res;
  });
};
export const addLine = (data) => {
  return request.post('/resource/line/add', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const getDetailLine = (data) => {
  return request.get('/resource/line/detail', data).then((res) => {
    return res;
  });
};
export const editLine = (data) => {
  return request.post('/resource/line/update', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const deleteLine = (data) => {
  return request.post('/resource/line/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
export const batchDeleteLine = (data) => {
  return request.post('/resource/line/batch/delete', data, { showLoading: true }).then((res) => {
    return res;
  });
};
// 线路标记点列表
export const getLineMarkList = (data) => {
  return request.get('/resource/line/mark/list', data).then((res) => {
    return res;
  });
};
// 线路标记点新增
export const addLineMark = (data) => {
  return request.post('/resource/line/mark/add', data).then((res) => {
    return res;
  });
};
// 线路标记点详情
export const getLineMarkDetail = (data) => {
  return request.get('/resource/line/mark/detail', data).then((res) => {
    return res;
  });
};
// 线路标记点修改
export const getLineMarkUpdate = (data) => {
  return request.post('/resource/line/mark/update', data).then((res) => {
    return res;
  });
};
// 线路标记点删除
export const getLineMarkDelete = (data) => {
  return request.post('/resource/line/mark/delete', data).then((res) => {
    return res;
  });
};
