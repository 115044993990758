<template>
  <el-drawer
    :title="drawerTitle"
    custom-class="g-drawer flexc"
    :wrapper-closable="false"
    :visible="drawerVisible"
    size="90%"
    @open="open"
    @close="close"
  >
    <div v-if="showMap" class="g-drawer-c flex">
      <map-add-point ref="addPoint" class="map-wrap" :datamap="mapdata" :detail-id="drawerId" :mode="mapDescMap[drawerTypeDesc]" @drivingResult="drivingResult" />
      <course-form
        ref="courseForm"
        class="form-wrap"
        :title="drawerTitle"
        :mode="drawerTypeDesc"
        :form="form"
        :driving-result-obj="drivingResultObj"
        :company-info="companyInfo"
        @resultChange="resultChange"
        @addressChange="onAddressChange"
        @getDetailMap="getDetailMap"
      />
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="danger" @click="deleteClick">删除</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="changeEdit">编辑</el-button>
    </div>
  </el-drawer>
</template>

<script>
import MapAddPoint from '@/components/MapAddPoint';
import CourseForm from './components/courseForm';
import { addLine, editLine, getDetailLine, deleteLine } from '@/api/line';
// import businessLineService from '@/api/line';
export default {
  components: {
    MapAddPoint,
    CourseForm,
  },
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return false;
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {
          // id: null,
          // name: '',
        };
      },
    },
  },
  data() {
    return {
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增线路',
        'edit': '编辑线路',
        'detail': '查看线路',
      },
      mapDescMap: {
        'add': 1,
        'edit': 2,
        'detail': 3,
      },
      mapdata: { startAddress: '', terminalAddress: '', startLnglat: '', terminalLnglat: '' },
      drivingResultObj: {},
      // dialogVisible: false,
      // dialogData: { flag: 1 },
      form: {
        id: undefined,
        name: undefined,
        productId: undefined,
        productName: undefined,
        fuel: undefined,
        startId: undefined,
        startName: undefined,
        terminalId: undefined,
        terminalName: undefined,
        mileage: undefined,
        duration: undefined,
        hour: undefined,
        minute: undefined,
      },
      // unit: '',
      startAddr: null, // 装货地
      terminalAddr: null, // 卸货地
      // productData: [],
      // mileage: undefined,
      showMap: false,
      // loading: false,
    };
  },

  computed: {
    formDisabled() {
      return this.drawerType === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
  },

  watch: {
    // 监听路由变化，如果发生变化则关闭drawer
    '$route': function(to, from) {
      this.drawerVisible = false;
    },
    // drawerVisible: {
    //   handler: function(visible) {
    //     if (visible) {
    //       this.showMap = true;
    //       this.drawerTypeDesc = this.drawerType;
    //       if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
    //         this.$nextTick(() => {
    //           this.getDetail();
    //         });
    //       }
    //     } else {
    //       this.showMap = false;
    //     }
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    open() {
      this.showMap = true;
      this.drawerTypeDesc = this.drawerType;
      if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
        this.$nextTick(() => {
          this.getDetail();
        });
      }
    },
    close() {
      this.showMap = false;
      this.drivingResultObj = {};
      this.mapdata = {
        startAddress: '',
        startLnglat: '',
        terminalAddress: '',
        terminalLnglat: '',
      };
      this.$refs.courseForm.resetForm();
      this.$emit('update:drawerVisible', false);
    },
    getDetail() {
      getDetailLine({ id: this.drawerId }).then((res) => {
        this.$refs.courseForm.getDetail(JSON.parse(JSON.stringify(res)));
        let newPointAndMarks;
        if (res.lineMarkVOList) {
          newPointAndMarks = res.lineMarkVOList.map(d => {
            return {
              id: d.id || '',
              lngLat: d.addrPoint,
              remark: d.remark,
              sequence: 0,
              type: +d.resType,
            };
          });
        }
        const mapdata = {
          startAddress: res.loadAddrDetail,
          terminalAddress: res.unloadAddrDetail,
          startLnglat: res.loadAddrPoint,
          terminalLnglat: res.unloadAddrPoint,
          allPoint: [
            { lngLat: res.loadAddrPoint, remark: '', sequence: 0, type: 3 }, { lngLat: res.unloadAddrPoint, remark: '', sequence: 1, type: 4 },
            ...newPointAndMarks,
          ],
        };
        this.getDetailMap(mapdata);
      });
    },
    getDetailMap(data) {
      this.mapdata = data;
      this.$nextTick(() => {
        this.$refs.addPoint.autoFill = false;
      });
    },
    deleteClick() {
      const params = {
        id: this.drawerId,
      };
      deleteLine(params).then(() => {
        this.$baseMessage('删除成功!', 'success');
        this.$emit('drawerSubmit');
        this.close();
      });
    },
    changeEdit() {
      this.drawerTypeDesc = 'edit';
    },

    onAddressChange(type, id, row) {
      if (type === 'start') {
        // this.mapdata.startAddress = row.address;
        this.mapdata.startAddress = row.addrDetail || '';
        this.mapdata.startLnglat = row.addrPoint;
        this.startAddr = {
          ...row,
        };
      } else {
        // this.mapdata.terminalAddress = row.address;
        this.mapdata.terminalAddress = row.addrDetail || '';
        this.mapdata.terminalLnglat = row.addrPoint;
        this.terminalAddr = {
          ...row,
        };
      }
      if (this.startAddr && this.terminalAddr) {
        this.mapdata.allPoint = [
          {
            lngLat: this.startAddr.addrPoint,
            remark: this.startAddr.addrDetail,
            sequence: 0,
            type: 3,
          },
          {
            lngLat: this.terminalAddr.addrPoint,
            remark: this.terminalAddr.addrDetail,
            sequence: 1,
            type: 4,
          },
        ];
      }
    },

    drivingResult(result, autoFill) {
      this.drivingResultObj = { ...result, autoFill };
    },
    async resultChange(rightForm) {
      const allPoint = this.$refs.addPoint.mypoint.map((d) => {
        return {
          id: d.id || '',
          resType: '1',
          markName: d.remark,
          addrRegion: '',
          addrDetail: '',
          addrPoint: d.lngLat,
          remark: d.remark,
        };
      });
      const allMarker = this.$refs.addPoint.myMarker.map((d) => {
        return {
          id: d.id || '',
          resType: '2',
          markName: d.remark,
          addrRegion: '',
          addrDetail: '',
          addrPoint: d.lngLat,
          remark: d.remark,
        };
      });
      const params = {
        ...rightForm,
        lineMarkDTOList: allPoint.concat(allMarker),
      };
      try {
        const apiMap = {
          'add': addLine,
          'edit': editLine,
        };
        const res = await apiMap[this.drawerTypeDesc](params);
        if (res) {
          this.$baseMessage(`${this.drawerTitle}成功!`, 'success');
          this.$emit('drawerSubmit');
          this.close();
        }
      } catch (error) {
        console.log(error);
      }
    },
    submit() {
      this.$refs.courseForm.submit();
    },
  },
};

</script>
<style lang='scss' scoped>
  .form-wrap {
    position: absolute;
    right: 30px;
    top: 40px;
    margin: 0 10px 0 0;
    width: 400px;
  }
// }
::v-deep {
  .el-select {
    width: 100%;
  }
}
.combine-item {
  display: flex;
  align-items: center;
  &::v-deep {
    .el-button {
      border-left: none;
      height: 36px;
      border-radius: 0 1px 1px 0;
    }
  }
}
.map {
  width: 100%;
  height: 400px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.5);
}
.map-show {
  position: relative;
  top: -10px;
}
.footer-btn {
  border-radius: 4px;
}
</style>
