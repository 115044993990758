<template>
  <div class="map-panel">
    <div id="map" class="map" />
    <div v-if="startAddress && terminalAddress" class="my-tab-container">
      <div ref="points" class="my-tab" :style="pointsStyle">
        <div class="flex-c">
          <img class="address-icon left" :src="require('@/assets/images/map/start.svg')">
          <div class="left bold-font">{{ startAddress || "--" }}</div>
        </div>
        <!--        <div v-for="item in allPoint" :key="item.val" v-show="getAddress(`${item.lng},${item.lat}`) && getAddress(`${item.lng},${item.lat}`).d !== 2">-->
        <!-- <template v-for="item in allPoint">
        </template> -->
        <div v-for="item in mypoint" :key="item.val" class="flex-c">
          <div class="address-circle left" style="border-color: #fcb910" />
          <div class="left slim-font">{{ item.remark || "--" }}</div>
          <i v-if="mode===1||mode===2" class="iconfont icon-delete-bin-line" @click="removeRoute(item)" />
        </div>
        <!-- <div v-for="item in allPoint" :key="item.val" class="flex-c">
          <div class="address-circle left" style="border-color: #fcb910" />
          <div class="left slim-font">{{ pointMap[`${item.lng},${item.lat}`] || "--" }}</div>/1{{ item }}
          <i class="iconfont icon-delete-bin-line" @click="removeRoute(item)" />
        </div> -->
        <div v-for="item in allMarker" :key="item.val" class="flex-c">
          <div class="address-circle left" :style="{ borderColor: '#04CA03' }" />
          <div class="left slim-font">{{ markerMap[item.lngLat] || "--" }}</div>
          <i v-if="mode===1||mode===2" class="iconfont icon-delete-bin-line" @click="removeMarker(item)" />
        </div>
        <!--        <div class="address-icon left" style="border-color: #f44436" />-->
        <div class="flex-c">
          <img class="address-icon left" :src="require('@/assets/images/map/terminal.svg')" @click="removeMarker(item)">
          <div class="left bold-font">{{ terminalAddress || "--" }}</div>
        </div>
      </div>
      <div ref="panel" class="panel">
        <el-tabs v-model="tabModel" type="card" @tab-click="policyClick">
          <el-tab-pane label="推荐线路" name="1" />
          <el-tab-pane label="最短路程" name="2" />
          <el-tab-pane label="不走高速" name="3" />
        </el-tabs>
        <div id="panel2" :style="panelStyle" />
      </div>
    </div>
    <img v-show="addFlag" id="marker" class="marker" :src="require('@/assets/images/map/marker.png')">
    <el-dialog :visible.sync="centerDialogVisible" :modal="false" width="25%" center>
      <div class="title">添加标记</div>
      <div>
        <el-input v-model="markerName" placeholder="请输入标记内容" maxlength="60" show-word-limit />
      </div>
      <div v-if="mode===1||mode===2" slot="footer" class="right" style="margin: -30px 10px 5px 0">
        <el-button @click="deleteMarker">删 除</el-button>
        <el-button type="primary" @click="saveMarker">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
// import { getLineMarkList, addLineMark, getLineMarkUpdate, getLineMarkDelete } from '@/api/line';
export default {
  props: {
    mode: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    datamap: {
      type: Object,
      default: () => {
        return {};
      },
    },
    detailId: {
      type: [Number, String],
      default: () => {
        return '';
      },
    },
  },
  data() {
    return {
      pointsStyle: 'max-height:250px',
      panelStyle: 'max-height: calc(100vh - 580px);',
      isInit: true,
      mapInstance: null,
      tabModel: '1',
      tabModelBuffer: '1',
      linePolicy: null,
      startAddress: '',
      terminalAddress: '',
      startLnglat: '',
      terminalLnglat: '',
      driving: null,
      route: null,
      AMap: null,
      allMarker: [],
      allPoint: [],
      myMarker: [], // 标记点
      mypoint: [], // 途经点
      initMypoint: [],
      mypointBuffer: [], // 途经点
      geocoder: null,
      path: [],
      dragFlag: 0,
      addFlag: 0,
      centerDialogVisible: false,
      markerName: '',
      markerInfo: {},
      markerMap: {},
      pointMap: {},
      currentMark: null,
      // isCollapse: false,
      markerList: [],
      isCover: 0,
      isReal: true,
      autoFill: true,
    };
  },
  computed: {
    // initListen() {
    //   const { AMap, datamap } = this;
    //   return {
    //     AMap,
    //     datamap,
    //   };
    // },
  },
  watch: {
    // AMap: {
    //   handler() {
    //     this.reloadDriving(this.datamap);
    //   },
    // },
    datamap: {
      immediate: true,
      deep: true,
      handler(val) {
        this.reloadDriving(val);
      },
    },
    // initListen: {
    //   deep: true,
    //   handler(val) {
    //     if (val.AMap && val.datamap) {
    //       this.initPlan();
    //     }
    //   },
    // },
    mypoint: {
      deep: true,
      handler(val) {
        if (val && val.length) {
          if (val.length === this.allPoint.length) {
            this.mypointBuffer = val;
            if (this.isInit) {
              this.loadPlan(val, false);
              this.isInit = false;
            } else {
              this.loadPlan(val, true);
            }
          }
        }
      },
    },
  },
  mounted() {
    this.mouseFollow();
    this.loadMap();
  },
  created() {
    this.$eventBus.$on('update-flag', this.updateFlag);
  },
  destroyed() {
    this.$eventBus.$off('update-flag', this.updateFlag);
    const pic = document.getElementById('marker');
    const self = this;
    document.removeEventListener('mousemove', function(e) {
      const x = e.pageX;
      const y = e.pageY;
      // 一定要给left和top添加px单位
      pic.style.left = self.isCollapse ? x - 109 + 'px' : x - 266 + 'px';
      pic.style.top = self.isCollapse ? y - 212 + 'px' : y - 212 + 'px';
    });
  },
  methods: {
    init() {
      this.tabModel = '1';
      this.tabModelBuffer = '1';
      this.linePolicy = this.AMap.DrivingPolicy.LEAST_TIME;
      this.startAddress = '';
      this.startLnglat = '';
      this.terminalAddress = '';
      this.terminalLnglat = '';
      this.allMarker = [];
      this.allPoint = [];
      this.route.destroy();
      this.route = null;
      this.dragFlag = 0;
      this.path = [];
      this.myMarker = [];
      this.mypoint = [];
      this.addFlag = 0;
      this.currentMark = null;
      // this.isCollapse = false;
      this.markerList = [];
      this.centerDialogVisible = false;
      this.markerName = '';
      this.markerInfo = {};
      this.markerMap = {};
      this.pointMap = {};
      this.mapInstance.clearMap();
    },

    loadMap() {
      AMapLoader.load({
        key: '2ce614857c44af9c3cc5f2691e537ed9', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Geocoder', 'AMap.Driving', 'AMap.DragRoute'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '1.3.2', // Loca 版本，缺省 1.3.2
        },
      })
        .then((AMap) => {
          // this.$eventBus.$emit('refresh-map', AMap);
          this.AMap = AMap;
          this.linePolicy = this.AMap.DrivingPolicy.LEAST_TIME;
          this.driving = new this.AMap.Driving({
            panel: 'panel2',
            policy: this.AMap.DrivingPolicy.LEAST_TIME,
          }); // 构造拖拽导航类
          this.mapInstance = new AMap.Map('map', {
            resizeEnable: true,
            center: [116.397428, 39.90923], // 地图中心点
            zoom: 13, // 地图显示的缩放级别
          });
          this.mapInstance.on('click', (e) => {
            if (this.addFlag === 1) {
              this.addMarker(e);
            }
            this.addFlag = 0;
          });
          const self = this;
          this.mapInstance.on('contextmenu', (e) => {
            this.addFlag = 0;
            const pic = document.getElementById('marker');
            document.removeEventListener('mousemove', function(e) {
              const x = e.pageX;
              const y = e.pageY;
              // 一定要给left和top添加px单位
              pic.style.left = self.isCollapse ? x - 109 + 'px' : x - 266 + 'px';
              pic.style.top = self.isCollapse ? y - 212 + 'px' : y - 212 + 'px';
            });
          });
          this.geocoder = new AMap.Geocoder();
          if (this.startLnglat && this.terminalLnglat) {
            const start = [+this.startLnglat.split(',')[0], +this.startLnglat.split(',')[1]];
            const end = [+this.terminalLnglat.split(',')[0], +this.terminalLnglat.split(',')[1]];
            let path = [];
            const markers = [];
            const point = [];
            this.datamap.allPoint.map((item) => {
              if (item.type === 1) {
                path.push([+item.lngLat.split(',')[0], +item.lngLat.split(',')[1]]);
                point.push(item);
              } else if (item.type === 2) {
                markers.push(item);
              }
            });
            if (path.length === 0) {
              path = [start, end];
            }
            // this.path = path;
            // console.log(123);
            // this.loadRoute(path, this.AMap.DrivingPolicy.LEAST_TIME);
            // this.loadMarkers(markers);
          }
        })
        .catch(() => {});
    },
    mouseFollow() {
      const pic = document.getElementById('marker');
      // const self = this;
      document.addEventListener('mousemove', function(e) {
        const x = e.pageX;
        const y = e.pageY;
        // 一定要给left和top添加px单位
        // pic.style.left = self.isCollapse ? x - 109 + 'px' : x - 266 + 'px';
        // pic.style.top = self.isCollapse ? y - 212 + 'px' : y - 212 + 'px';
        pic.style.left = x - 180 + 'px';
        pic.style.top = y - 110 + 'px';
      });
    },
    policyClick(tab) {
      if (tab.name === this.tabModelBuffer) return;
      // this.$emit('policyChange');
      this.tabModelBuffer = tab.name;
      let linePolicy;
      if (+tab.name === 1) {
        // 推荐线路
        linePolicy = this.AMap.DrivingPolicy.LEAST_TIME;
      }
      if (+tab.name === 2) {
        // 最短路程
        linePolicy = this.AMap.DrivingPolicy.LEAST_DISTANCE;
      }
      if (+tab.name === 3) {
        // 不走高速
        linePolicy = this.AMap.DrivingPolicy.LEAST_FEE;
      }
      this.autoFill = true;
      this.driving.setPolicy(linePolicy); // 速度最快
      this.loadPlan(this.mypoint, true);
      this.linePolicy = linePolicy;
      this.loadRoute(this.path, linePolicy, 1);
    },
    initPlan() {
      let waypoints = [];
      if (this.datamap.allPoint && this.datamap.allPoint.length) {
        waypoints = this.datamap.allPoint && this.datamap.allPoint.filter((e) => e.type === 1);
      }
      if (waypoints.length === 0) {
        this.loadPlan(waypoints, false);
      }
    },
    loadPlan(waypoints, isFill) {
      if (this.startLnglat && this.terminalLnglat) {
        const startPlanArr = [+this.startLnglat.split(',')[0], +this.startLnglat.split(',')[1]];
        const terminalPlanArr = [+this.terminalLnglat.split(',')[0], +this.terminalLnglat.split(',')[1]];
        const waypointPlanArr = (waypoints || []).map((e) => {
          return [+e.lngLat.split(',')[0], +e.lngLat.split(',')[1]];
        });
        const self = this;
        this.driving.search(startPlanArr, terminalPlanArr, { waypoints: waypointPlanArr }, function(status, result) {
          // 未出错时，result即是对应的路线规划方案
          if (status === 'complete') {
            self.$emit('drivingResult', result.routes[0], isFill);
          }
        });
      }
    },

    // 保存打点标签
    saveMarker() {
      const lngLat = `${this.markerInfo.lng},${this.markerInfo.lat}`;
      this.allMarker = this.allMarker.map((e) => {
        if (e.lngLat === lngLat) {
          e.remark = this.markerName;
        }
        return e;
      });
      this.myMarker &&
        this.myMarker.map((e) => {
          if (e.lngLat === lngLat && e.type === 2) {
            this.markerMap[e.lngLat] = this.markerName;
            return (e.remark = this.markerName);
          }
        });
      // 单独打点接口暂时不调取
      // const params = {
      //   id: this.markerInfo.id || '',
      //   resType: '1',
      //   markName: this.markerInfo.remark,
      //   addrPoint: this.markerInfo.lngLat,
      //   lineId: this.detailId,
      //   addrRegion: '',
      //   addrDetail: '',
      //   remark: '',
      // };
      // this.savePointApi(params);
      this.centerDialogVisible = false;
    },
    // 获取标记点1 途径点列表接口暂时不调取
    getLineMarkListData() {
      // const params = {
      //   id: this.detailId,
      // };
      // getLineMarkList(params).then((res) => {
      //   console.log(res, 'res11');
      // });
    },
    // 保存点 接口暂时不调取
    savePointApi(params) {
      // addLineMark(params).then((res) => {
      //   console.log(res, 'res');
      // });
    },
    // 更新点接口暂时不调取
    getLineMarkUpdateApi(params) {
      // console.log(params, 'sss');
      // getLineMarkUpdate(params).then((res) => {
      //   console.log(res, 'res');
      // });
    },
    // 删除点接口暂时不调取
    getLineMarkDeleteApi(params) {
      // console.log(params, 'sss');
      // getLineMarkDelete(params).then((res) => {
      //   console.log(res, 'res');
      // });
    },
    removeMarker(item) {
      const allMarker = this.allMarker.filter((e) => e.lngLat !== item.lngLat);
      this.allMarker = allMarker;
      this.myMarker = allMarker.map((e) => {
        return {
          id: e.id || '',
          lngLat: e.lngLat,
          sequence: e.sequence,
          type: e.type,
          remark: e.remark,
        };
      });
      this.mapInstance.remove(this.markerList);
      const markerList = [];
      allMarker &&
        allMarker.map((e) => {
          const lnglatSplit = e.lngLat.split(',');
          const marker = new AMap.Marker({
            position: [lnglatSplit[0], lnglatSplit[1]],
            icon: new AMap.Icon({
              image: require('@/assets/images/map/marker.png'),
            }),
          });
          marker.setExtData({ lng: lnglatSplit[0], lat: lnglatSplit[1] });
          marker.on('click', this.markerClick);
          markerList.push(marker);
          this.mapInstance.add(marker);
        });
      this.markerList = markerList;
    },
    deleteMarker() {
      const lngLatStr = this.markerInfo.lng + ',' + this.markerInfo.lat;
      const markerInfoIndex = this.myMarker.findIndex((e) => e.lngLat && e.lngLat === lngLatStr);
      if (markerInfoIndex !== -1) {
        this.myMarker.splice(markerInfoIndex, 1);
      }
      const allMarkerIndex = this.allMarker.findIndex((e) => e.lngLat && e.lngLat === lngLatStr);
      if (allMarkerIndex !== -1) {
        this.allMarker.splice(allMarkerIndex, 1);
      }
      this.mapInstance.remove(this.currentMark);
      this.centerDialogVisible = false;
    },

    markerClick(e) {
      const extData = e.target.getExtData();
      this.markerInfo = extData;
      this.currentMark = e.target;
      // this.markerName = this.getAddress(`${extData.lng},${extData.lat}`).e;
      this.markerName = this.markerMap[`${extData.lng},${extData.lat}`];
      this.centerDialogVisible = true;
    },
    addMarker(e) {
      const marker = new AMap.Marker({
        position: [e.lnglat.lng, e.lnglat.lat],
        icon: new AMap.Icon({
          image: require('@/assets/images/map/marker.png'),
        }),
      });
      this.markerInfo = e.lnglat;
      marker.setExtData(e.lnglat);
      this.markerName = '';
      this.currentMark = marker;
      marker.on('click', this.markerClick);
      this.markerList.push(marker);
      this.centerDialogVisible = true;
      this.mapInstance.add(marker);
      const lngLat = e.lnglat;
      lngLat['lngLat'] = `${lngLat.lng},${lngLat.lat}`;
      this.allMarker.push(lngLat);
      this.myMarker.push({
        lngLat: `${e.lnglat.lng},${e.lnglat.lat}`,
        sequence: this.myMarker.length,
        type: 2,
        remark: '',
      });
    },
    updateFlag() {
      // this.isCollapse = collapse;
      this.addFlag = 1;
    },
    removeRoute(item) {
      let clickItem;
      if (item.lngLat) {
        clickItem = {
          lat: +item.lngLat.split(',')[1],
          lng: +item.lngLat.split(',')[0],
        };
      } else {
        clickItem = item;
      }
      const path = this.path;
      const index = path.findIndex((e) => e.lat === clickItem.lat && e.lng === clickItem.lng);
      if (index !== -1) {
        path.splice(index, 1);
      }
      this.loadRoute(path, this.linePolicy);
    },
    loadMarkers(markers) {
      this.allMarker = markers;
      const markerMap = {};
      const markerList = [];
      const myMarker = [];
      markers &&
        markers.map((e) => {
          const marker = new AMap.Marker({
            position: [e.lngLat.split(',')[0], e.lngLat.split(',')[1]],
            icon: new AMap.Icon({
              image: require('@/assets/images/map/marker.png'),
            }),
          });
          markerMap[e.lngLat] = e.remark;
          marker.setExtData({ lng: e.lngLat.split(',')[0], lat: e.lngLat.split(',')[1] });
          marker.on('click', this.markerClick);
          markerList.push(marker);
          this.mapInstance.add(marker);
          myMarker.push({
            id: e.id || '',
            lngLat: `${e.lngLat.split(',')[0]},${e.lngLat.split(',')[1]}`,
            sequence: e.sequence,
            type: 2,
            remark: e.remark,
          });
        });
      this.myMarker = myMarker;
      this.markerList = markerList;
      this.markerMap = markerMap;
    },
    loadRoute(path, type) {
      if (this.route !== null) {
        this.route.destroy();
        this.route = null;
      }
      this.route = new this.AMap.DragRoute(this.mapInstance, path, type, {
        polyOptions: {
          zIndex: '999',
          strokeWeight: 6,
          strokeOpacity: 1,
          strokeStyle: 'solid',
          outlineColor: 'transparent',
          strokeColor: '#0091FF',
          lineJoin: 'round',
          lineCap: 'round',
          showDir: true,
        },
        startMarkerOptions: {
          icon: require('@/assets/images/map/start-shadow.svg'), // 添加 Icon 图标 URL
        },
        midMarkerOptions: {
          icon: require('@/assets/images/map/point.png'), // 添加 Icon 图标 URL
        },
        endMarkerOptions: {
          icon: require('@/assets/images/map/terminal-shadow.svg'), // 添加 Icon 图标 URL
        },
      }); // 构造拖拽导航类
      this.route.search(); // 查询导航路径并开启拖拽导航
      this.route.on('complete', (e) => {
        this.dragFlag++;
        // 时间 routes.route.time number
        if (this.dragFlag > 1) {
          let pathTemp = [];
          const startArr = [path[0]];
          const endArr = [path[path.length - 1]];
          pathTemp = startArr.concat(this.route.getWays()).concat(endArr);
          this.path = pathTemp;
          this.mapInstance.clearMap();
          this.route.destroy();
          this.route = null;
          this.loadMarkers(this.allMarker);
          this.loadRoute(pathTemp, type);
          this.dragFlag = 0;
        }
        this.allPoint = this.route.getWays();
        // const mypoint = this.initMypoint;
        const mypoint = [];
        for (const [index, item] of this.allPoint.entries()) {
          this.geocoder.getAddress([item.lng, item.lat], (status, result) => {
            let address;
            if (status === 'complete' && result.regeocode) {
              address = result.regeocode.formattedAddress;
            } else {
              address = '未知道理';
            }
            this.pointMap[`${item.lng},${item.lat}`] = address;
            let matchPoint;
            if (this.initMypoint.length) {
              matchPoint = this.initMypoint.filter(d => d.lngLat === `${item.lng},${item.lat}`);
            } else {
              matchPoint = [];
            }
            mypoint.push({
              id: matchPoint.length ? matchPoint[0]?.id || '' : '',
              lngLat: `${item.lng},${item.lat}`,
              sequence: index + 1,
              type: 1,
              remark: address,
            });
          });
        }
        this.mypointBuffer = mypoint;
        if (this.mypointBuffer.length !== this.allPoint.length) {
          this.mypoint = mypoint;
        }
        if (this.allPoint.length === 0) {
          this.loadPlan([], this.mode === 1 || (this.mypointBuffer.length === 0 && !this.isReal));
          this.mypoint = [];
        }
        if (this.isReal) {
          this.isReal = false;
        }
      });
    },
    getAddress(val) {
      return this.mypoint.find((item) => {
        return item.lngLat === val;
      });
    },
    reloadDriving(val) {
      // eslint-disable-next-line no-unused-vars
      let start, end;
      this.startAddress = val.startAddress;
      this.terminalAddress = val.terminalAddress;
      this.startLnglat = val.startLnglat;
      this.terminalLnglat = val.terminalLnglat;
      if (this.startLnglat === '') {
        start = [];
      } else {
        start = [+this.startLnglat.split(',')[0], +this.startLnglat.split(',')[1]];
      }
      if (this.terminalLnglat === '') {
        end = [];
      } else {
        end = [+this.terminalLnglat.split(',')[0], +this.terminalLnglat.split(',')[1]];
      }
      let path = [];
      const waypoints = [];
      const startPoint = [];
      const terminalPoint = [];
      const point = [];
      const markers = [];
      val.allPoint &&
        val.allPoint.map((item) => {
          if (item.type === 1) {
            point.push(item);
            waypoints.push([+item.lngLat.split(',')[0], +item.lngLat.split(',')[1]]);
          } else if (item.type === 2) {
            markers.push(item);
          } else if (item.type === 3) {
            startPoint.push([+item.lngLat.split(',')[0], +item.lngLat.split(',')[1]]);
          } else if (item.type === 4) {
            terminalPoint.push([+item.lngLat.split(',')[0], +item.lngLat.split(',')[1]]);
          }
        });
      this.initMypoint = point;
      if (waypoints.length === 0) {
        path = startPoint.concat(terminalPoint);
      } else {
        path = startPoint.concat(waypoints).concat(terminalPoint);
      }
      this.path = path;
      setTimeout(() => {
        if (this.AMap !== null) {
          if (path[0] && path[0].length && path[1] && path[1].length) {
            this.loadRoute(path, this.AMap.DrivingPolicy.LEAST_TIME);
          }
          this.loadMarkers(markers);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-tabs--card > .el-tabs__header {
    border-bottom: none;
    margin: 0 0 5px;
  }
  .el-tabs__nav-wrap{
    padding: 0;
  }
  .el-tabs__nav-next,.el-tabs__nav-prev{
    display: none;
  }
  .el-tabs__nav {
    margin: 0!important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs__item.is-active {
    background-color: #dbdce0;
    font-weight: 600;
    color: #26272a;
  }
  .el-tabs__item {
    &:hover {
      font-weight: 600;
      color: #26272a;
    }
  }
  .el-dialog__header {
    display: none;
  }
  .amap-info-close {
    display: none;
  }
  .title {
    color: #04122b;
    margin-bottom: 8px;
  }
  .btn-bar {
    margin-top: 12px;
  }
  .infoShow {
    position: relative;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #04122b;
      margin-bottom: 8px;
    }
    .btn-bar {
      margin-top: 12px;
    }
  }
  .amap-info-content {
    padding: 16px;
    width: 308px;
    height: 135px;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    border: none;
  }
  .amap-info-content:hover {
    border: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  }
  .amap-info-contentContainer:hover .amap-info-content {
    border: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  }
}
.marker {
  position: absolute;
  width: 25px;
  height: 34px;
}
.v-line {
  margin: 3px 12px 0;
  height: 14px;
  border-left: 1px solid #dcdfe6;
}
.mile-bar {
  color: #5a6073;
  margin: 16px 0;
}
.panel-title {
  font-size: 16px;
  font-weight: 600;
  color: #04122b;
}
.forbidden {
  cursor: pointer;
  width: 22px;
}
.line {
  margin: 10px auto;
  width: 280px;
  height: 1px;
  //border-top: 1px solid #ebeef5;
}
.my-tab{
  >div{
    margin-bottom: 10px;
  }
  >:last-child{
    margin-bottom: 0;
  }
}
.slim-font {
  width: 230px;
  font-size: 14px;
  color: #5a6073;
}
.bold-font {
  width: 230px;
  font-size: 14px;
  font-weight: 600;
  color: #04122b;
}
.address-icon {
  // height: 30px;
  // font-size: 20px;
  margin: 0 6px 0 -6px;
}
.address-circle {
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-radius: 50%;
  margin:0 15px 0 0px;
}
.map-panel {
  position: relative;
  width: 100%;
  height: 100%;
  #map {
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
  }
  .my-tab-container {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 325px;
    padding: 10px;
    margin: 5px auto;
    // margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .panel {
    flex:1;
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(90, 96, 115, 0.14);
    width: 345px;
    background-color: white;
    //max-height: calc(100vh - 570px);
    //overflow-y: auto;
    padding: 20px;
    margin: 5px 0 10px 0;
    #panel2 {
      border-radius: 4px;
      //box-shadow: 0 2px 20px 0 rgba(90, 96, 115, 0.14);
      //width: 345px;
      background-color: white;
      //max-height: calc(100vh - 650px);
      overflow-y: auto;
      margin: 5px 0 10px 0;
    }
  }
  .my-tab {
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(90, 96, 115, 0.14);
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    padding: 20px;
    width: 345px;
    margin: -5px 0 10px 0;
  }
  ::v-deep {
    .amap-lib-driving {
      border: 1px solid #dcdfe6;
    }
    .amap-call {
      display: none;
    }
    .amap-lib-driving .plan dt {
      line-height: 30px;
    }
  }
}
</style>
