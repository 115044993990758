<template>
  <div class="basic-data-wrap flex">
    <leftPanel ref="leftPanel" :class="['left-wrap',isFold?'left-half':'left-all']" :is-fold.sync="isFold" company-type="all" @changeCompany="changeCompany" @leftCompanyData="leftCompanyData" />
    <div :class="['right-wrap g-search-form-table',isFold?'right-half':'right-all']">
      <search-form id="search-form" :model="form" :count="2" label-width="auto" :collapse="false" @search="searchHandle">
        <search-item label="" prop="lineName">
          <el-input v-model.trim="form.lineName" clearable placeholder="线路名称" />
        </search-item>
        <search-item label="" prop="productName">
          <el-input v-model.trim="form.productName" clearable placeholder="产品名称" />
        </search-item>
      </search-form>
      <Table
        :loading="loading"
        :table-height="tableHeight"
        :show-selection-change="true"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-btns="tableBtns"
        :pagination="pagination"
        :multiple-selection.sync="multipleSelection"
        @selectTime="selectTime"
        @changeSort="changeSort"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'duration'">{{ formatSeconds2(row.duration) || '-' }}</div>
        </template>
      </Table>
      <Drawer :drawer-visible.sync="drawerVisible" :drawer-type="drawerType" :drawer-id="id" :company-info="companyInfo" @drawerSubmit="drawerSubmit" />
    </div>
  </div>
</template>

<script>
import { getLine, batchDeleteLine } from '@/api/line';
import { exportByJson, parseTime } from '@/utils';
import leftPanel from '@/views/basicData/components/leftPanel';
import Drawer from './drawer';
export default {
  name: 'BasicdataBusinessline',
  components: { leftPanel, Drawer },
  filters: { parseTime },
  data() {
    return {
      isFold: false,
      tableHeight: 'auto',
      companyInfo: {}, // 当前所选的左侧公司信息 相关接口需要此参数
      loading: false,
      form: {},
      tableBtns: [
        {
          label: '新增线路',
          icon: 'el-icon-circle-plus-outline',
          evt: () => {
            this.openDialog('add');
          },
        },
        {
          label: '删除',
          type: 'danger',
          // plain: true,
          evt: () => {
            this.delete();
          },
        },
        {
          label: '导入',
          type: 'default',
          evt: () => {
            this.$router.push('sourceGoods/businessLine/detail');
          },
        },
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
      ],
      tableCols: [
        {
          label: '线路名称', prop: 'lineName', fixed: 'left', type: 'jump', width: 180, evt: (data) => { this.openDialog('detail', data); },
        },
        { label: '产品名称', prop: 'productName' },
        { label: '装货地址', prop: 'loadAddrDetail' },
        { label: '卸货地址', prop: 'unloadAddrDetail' },
        { label: '里程数(km)', prop: 'distance' },
        { label: '备注', prop: 'remark' },
        // { label: '预计时长', prop: 'duration', type: 'customize' },
        { label: '创建人', prop: 'createUsername' },
        { label: '创建时间 ', prop: 'createTime', type: 'filterTime', width: '160' },
        { label: '更新人', prop: 'updateUsername' },
        { label: '更新时间', prop: 'updateTime', sortable: 'custom', headTimeSelect: true, width: 170, type: 'filterTime' },
        {
          label: '操作',
          type: 'operation',
          fixed: 'right',
          btns: [
            {
              label: '编辑',
              evt: (data) => {
                this.openDialog('edit', data);
              },
            },
          ],
        },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      id: null,
      drawerType: 'add',
      drawerVisible: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize, true);
  },
  deactivated() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  methods: {
    handleResize() {
      const screenHeight = document.documentElement.clientHeight;
      this.formHeight = document.getElementById('search-form').clientHeight;
      const otherHeight = this.formHeight + 14 + 82 + 20 + 56 + 52 + 20;
      this.tableHeight = screenHeight - otherHeight + 'px';
    },
    // 获取列表数据
    getList() {
      this.loading = true;
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: this.pagination.page,
        size: this.pagination.size,
      };
      getLine(params)
        .then((res) => {
          this.tableData = res?.records || [];
          this.pagination = {
            page: res?.current || 1,
            size: res?.size || 10,
            total: res?.total || 0,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询按钮操作
    searchHandle() {
      // 防止点击重置置空以下参数影响筛选
      this.form.companyId = this.companyInfo.companyId; // 企业id
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 删除所选
    delete() {
      if (!this.multipleSelection.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      const params = {
        ids: this.multipleSelection.map((d) => d.id) || null,
      };
      batchDeleteLine(params).then((res) => {
        this.$baseMessage('删除成功!', 'success');
        this.getList();
      });
    },
    // 前端导出excel
    onExport() {
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: 1,
        size: 9999,
      };
      getLine(params)
        .then((res) => {
          if (!res) {
            this.$baseMessage('暂无数据可导出!', 'error');
            return;
          }
          const header = ['线路名称', '产品名称', '装货地址', '卸货地址', '里程数(km)', '备注', '创建人', '创建时间', '更新人', '更新时间'];
          const exportData = res.records.map((item) => {
            return {
              lineName: item.lineName,
              ext1Name: item.ext1Name,
              productName: item.productName,
              loadAddrDetail: item.loadAddrDetail,
              unloadAddrDetail: item.unloadAddrDetail,
              distance: item.distance,
              remark: item.remark,
              // duration: item.duration,
              createUsername: item.createUsername,
              createTime: parseTime(item.updateTime),
              updateUsername: item.updateUsername,
              updateTime: parseTime(item.updateTime),
            };
          });
          exportByJson(exportData, header, '线路列表.xlsx');
        })
        .catch((err) => {
          console.log(err);
          this.$baseMessage('导出失败', 'error');
        });
    },
    changeSort(val) {
      console.log('[ val ] >', val);
    },
    selectTime(time) {
      this.form.updateTimeBegin = time ? time[0] : '';
      this.form.updateTimeEnd = time ? time[1] : '';
      this.getList();
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog(type, data) {
      this.id = data?.id || null;
      this.drawerType = type;
      this.drawerVisible = true;
    },
    // 抽屉提交事件
    drawerSubmit() {
      this.getList();
    },
    formatSeconds2(value) {
      let theTime = parseInt(value); // 秒
      let middle; // 分
      let hour; // 小时
      if (theTime >= 60) {
        middle = (Math.ceil((theTime / 60) * 100) / 100).toFixed(0);
        theTime = parseFloat(theTime % 60);
        if (middle >= 60) {
          hour = parseInt(middle / 60);
          middle = (Math.ceil((middle % 60) * 100) / 100).toFixed(0);
        }
      }
      if (hour) {
        return (hour || '-') + '小时' + (middle || '-') + '分钟';
      } else {
        return middle || '-' + '分钟';
      }
    },
    // left返回的id
    leftCompanyData(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
    // 左侧切换公司 重新发起请求
    changeCompany(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/basicData.scss";
</style>
