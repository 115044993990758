<template>
  <div class="course-form-wrap">
    <div class="course-form-title">{{ title }}</div>
    <el-form ref="form" class="form" :model="localForm" label-width="95px" :rules="formRules" :disabled="formDisabled">
      <el-form-item label="关联用户" prop="ext1">
        <div style="line-height: 15px;">
          <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="companyInfo" :show-bg="true" />
        </div>
      </el-form-item>
      <el-row :gutter="0" class="flex-c line-name">
        <el-col :span="14">
          <el-form-item
            label="线路名称"
            prop="startName"
          >
            <el-input v-model="localForm.startName" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <i class="iconfont icon-arrow-right-line" />
        <el-col :span="10">
          <el-form-item
            class="end-line"
            prop="endName"
          >
            <el-input v-model="localForm.endName" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="产品名称" prop="productId">
        <query-select v-model="localForm.productId" placeholder="请选择" value-key="id" value-label="prodName" :default-label="localForm.productName" clearable :search-fields="selectSearchFields.product" :remote="productRemote" @change="onChangeProduct">
          <template>
            <el-table-column label="产品名称" prop="prodName" width="100px" />
            <el-table-column label="产品单价(元/吨)" prop="prodPrice" />
            <el-table-column label="关联标签" prop="resType" />
          </template>
        </query-select>
      </el-form-item>
      <el-form-item label="" prop="loadAddrId" :rules="[{ required: true, message: '请选择装货地' }]" :class="{'has-select':localForm.loadAddrId}">
        <div class="relative">
          <img class="address-icon start" :src="require('@/assets/images/map/start.svg')">
          <!-- :disabled="mode!=='add'" -->
          <query-select
            v-model="localForm.loadAddrId"
            class="input-no-suffix"
            value-key="id"
            value-label="addrDetail"
            :default-label="localForm.loadAddrDetail"
            placeholder="请选择"
            clearable
            width="600px"
            :remote="addressRemote"
            :search-fields="selectSearchFields.address"
            @change="
              (id, row) => {
                onAddressChange('start', id, row);
              }
            "
          >
            <template>
              <el-table-column label="地址名称" prop="addrName" width="100px" />
              <el-table-column label="地址详情" prop="addrDetail" width="300px" />
              <el-table-column label="联系单位" prop="custName" />
            </template>
          </query-select>
          <div v-if="localForm.loadAddrId" class="contacts">联系人:{{ localForm.extMap.loadCustUserName }} {{ localForm.extMap.loadCustUserPhone }}</div>
        </div>
      </el-form-item>
      <el-form-item class="form-item" label="" prop="unloadAddrId" :rules="[{ required: true, message: '请选择卸货地' }]" :class="{'has-select':localForm.unloadAddrId}">
        <div class="relative">
          <img class="address-icon end" :src="require('@/assets/images/map/terminal.svg')">
          <!-- :disabled="mode!=='add'" -->
          <query-select
            v-model="localForm.unloadAddrId"
            class="input-no-suffix"
            value-key="id"
            value-label="addrDetail"
            :default-label="localForm.unloadAddrDetail"
            placeholder="请选择"
            clearable
            width="600px"
            :remote="addressRemote"
            :search-fields="selectSearchFields.address1"
            @change="
              (id, row) => {
                onAddressChange('end', id, row);
              }
            "
          >
            <template>
              <el-table-column label="地址名称" prop="addrName" width="100px" />
              <el-table-column label="地址详情" prop="addrDetail" width="300px" />
              <el-table-column label="联系单位" prop="custName" />
            </template>
          </query-select>
          <div v-if="localForm.unloadAddrId" class="contacts">联系人:{{ localForm.extMap.unloadCustUserName }} {{ localForm.extMap.unloadCustUserPhone }}</div>
        </div>
      </el-form-item>
      <el-form-item class="form-item relative" label="里程数" prop="distance">
        <div class="absolute unit">公里</div>
        <el-input v-model="localForm.distance" placeholder="请输入" @input="numberLimit('distance', 6, 3)" />
      </el-form-item>
      <el-form-item class="form-item relative" label="时长">
        <div class="duration-input">
          <el-input v-model="localForm.hourDuration" placeholder="请输入" @input="numberLimit('hourDuration', 4, 0)" @blur="durationBlur($event, 'hourDuration')" />
          <div class="duration-input-unit">小时</div>
          <el-input v-model="localForm.minDuration" placeholder="请输入" @input="minuteLimit('minDuration')" @blur="durationBlur($event, 'minDuration')" />
          <div class="duration-input-unit">分</div>
        </div>
      </el-form-item>
      <el-form-item class="form-item relative" label="备注" prop="remark">
        <el-input v-model="localForm.remark" show-word-limit maxlength="20" clearable />
      </el-form-item>
      <el-form-item class="form-item relative" label="运价" prop="price" @input="numberLimit('price', 5, 2)">
        <el-input v-model="localForm.extMap.price">
          <el-select slot="append" v-model="localForm.extMap.priceUnit" style="width: 100px;" placeholder="请选择">
            <el-option v-for="item in tranSportPriceUnitData.A" :key="item.value" :label="item.label" :value="item.value" />
            <!-- <el-option label="元 / 吨" value="1" />
            <el-option label="元 / 趟" value="2" />
            <el-option label="元 / 公里" value="3" /> -->
          </el-select>
        </el-input>
      </el-form-item>
      <!-- <el-form-item class="form-item relative" label="是否含税" prop="hasTax" :rules="[{ required: true, message: '请选择是否含税' }]"> -->
      <el-form-item label="是否含税" prop="needTax">
        <el-radio-group v-model="localForm.extMap.needTax">
          <el-radio label="1">含税</el-radio>
          <el-radio label="0">不含税</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span v-if="mode!=='detail'" slot="footer" class="dialog-footer">
      <div class="add-mark left" @click="addMarker">
        添加标记</div>
    </span>
  </div>
</template>

<script>
import { getProduct } from '@/api/product';
import { getAddr } from '@/api/address';
import { validateMileage, validateName, validateNumber, validatePrice } from '@/utils/validateRule';
export default {
  name: 'CourseForm',
  props: {
    title: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'add',
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
    drivingResultObj: {
      type: Object,
      default() {
        return {
          // id: null,
          // name: '',
        };
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      AMap: null,
      durationInit: 0,
      selectSearchFields: {
        address: [{ field: 'simpleAddress', label: '地址名称' }],
        address1: [{ field: 'simpleAddress', label: '地址名称' }],
        product: [{ field: 'prodName', label: '产品名称' }],
      },
      localForm: {
        startName: '',
        endName: '',
        hourDuration: '',
        minDuration: '',
        lineName: '', // ''线路名称2',
        productId: '', // ' 112,
        productName: '', // ' '产品名称2',
        loadAddrId: '', // ' 112,
        loadAddrRegion: '', // ' '装货三级地址2',
        loadAddrDetail: '', // ' '装货详细地址2',
        loadAddrPoint: '', // ' '装货经纬度2',
        unloadAddrId: '', // ' 112,
        unloadAddrRegion: '', // ' '卸货三级地址2',
        unloadAddrDetail: '', // ' '卸货详细地址2',
        unloadAddrPoint: '', // ' '卸货经纬度2',
        distance: '', // ' 112, // 里程数
        duration: '', // ' 112, // 时长
        remark: '', // ' '备注2',
        // lineMarkDTOList: [
        //   {
        //     markName: '', // ' '名称12',
        //     addrRegion: '', // ' '三级地址12',
        //     addrDetail: '', // ' '详细地址12',
        //     addrPoint: '', // ' '经纬度12',
        //     remark: '', // ' '备注12',
        //   },
        //   {
        //     markName: '', // ' '名称22',
        //     addrRegion: '', // ' '三级地址22',
        //     addrDetail: '', // ' '详细地址22',
        //     addrPoint: '', // ' '经纬度22',
        //     remark: '', // ' '备注22',
        //   },
        // ],
        extMap: {
          price: '', // ' '运价2',
          needTax: '', // ' '是否含税2',
          priceUnit: '1', // 单位
        },
      },
      formRules: {
        startName: [
          {
            required: true,
            validator: validateName('请输入线路名称'),
          },
        ],
        endName: [
          {
            required: true,
            validator: validateName('请输入线路名称'),
          },
        ],
        productId: [
          { required: true, message: '请选择产品名称' },
        ],
        loadAddrId: [
          { required: true, message: '请选择装货点' },
        ],
        unloadAddrId: [
          { required: true, message: '请选择卸货点' },
        ],
      },
      // typeData: undefined,
      rules: {
        validatePrice,
        validateName,
        validateMileage,
        validateNumber,
      },
      tranSportPriceUnitData: {
        A: [],
        O: {},
      },
    };
  },
  computed: {
    formDisabled() {
      return this.title === '查看线路';
    },
    // durationRequired() {
    //   return !this.localForm.hour && !this.localForm.minute && this.durationInit !== 0;
    // },
  },
  watch: {
    drivingResultObj(val) {
      this.drivingResultChange(val);
    },
  },
  created() {
    this.getDictionaryList();
    this.$eventBus.$on('refresh-map', this.refreshMap);
  },
  destroyed() {
    this.$eventBus.$off('refresh-map', this.refreshMap);
  },
  methods: {
    async getDictionaryList() {
      const params = ['dict_transport_price_unit'];
      const data = await this.$store.dispatch('dictionary/get', params);
      if (data) {
        this.tranSportPriceUnitData = data['dict_transport_price_unit'];
      }
    },
    resetForm() {
      this.localForm = {
        startName: '',
        endName: '',
        hourDuration: '',
        minDuration: '',
        lineName: '', // ''线路名称2',
        productId: '', // ' 112,
        productName: '', // ' '产品名称2',
        loadAddrId: '', // ' 112,
        loadAddrRegion: '', // ' '装货三级地址2',
        loadAddrDetail: '', // ' '装货详细地址2',
        loadAddrPoint: '', // ' '装货经纬度2',
        unloadAddrId: '', // ' 112,
        unloadAddrRegion: '', // ' '卸货三级地址2',
        unloadAddrDetail: '', // ' '卸货详细地址2',
        unloadAddrPoint: '', // ' '卸货经纬度2',
        distance: '', // ' 112, // 里程数
        duration: '', // ' 112, // 时长
        remark: '', // ' '备注2',
        lineMarkDTOList: [
          {
            markName: '', // ' '名称12',
            addrRegion: '', // ' '三级地址12',
            addrDetail: '', // ' '详细地址12',
            addrPoint: '', // ' '经纬度12',
            remark: '', // ' '备注12',
          },
          {
            markName: '', // ' '名称22',
            addrRegion: '', // ' '三级地址22',
            addrDetail: '', // ' '详细地址22',
            addrPoint: '', // ' '经纬度22',
            remark: '', // ' '备注22',
          },
        ],
        extMap: {
          price: '', // ' '运价2',
          needTax: '', // ' '是否含税2',
          priceUnit: '1', // 单位
        },
      };
    },
    getDetail(data) {
      const newForm = JSON.parse(JSON.stringify(data));
      newForm.startName = data.lineName.split('-')[0];
      newForm.endName = data.lineName.split('-')[1];
      const durationHour = this.formatSeconds2(+data.duration);
      const hour = durationHour[0];
      const minute = durationHour[1];
      // this.localForm.distance = data.distance ? (Math.round((+data.distance / 1000) * 100) / 100).toFixed(2) : undefined;
      newForm.hourDuration = hour;
      newForm.minDuration = minute;
      this.localForm = newForm;

      // this.$forceUpdate;
    },
    init() {
      this.durationInit = 0;
      this.$refs.form.resetFields();
    },
    // 产品下拉
    productRemote(query) {
      return getProduct({
        ...query,
        createUser: this.$store.state.common.AUV_USER_ID,
        companyId: this.companyInfo.companyId,
      }, false).then((res) => {
        return {
          data: res?.records || [],
          total: res?.total,
        };
      });
    },
    onChangeProduct(id, row) {
      if (row) {
        this.localForm.productName = row.prodName;
      } else {
        this.localForm.productName = undefined;
      }
    },
    addressRemote(query) {
      return getAddr({
        ...query,
        createUser: this.$store.state.common.AUV_USER_ID,
        companyId: this.companyInfo.companyId,
      }, false).then((res) => {
        return {
          data: res?.records || [],
          total: res?.total,
        };
      });
    },
    onAddressChange(type, id, row) {
      if (type === 'start') {
        this.localForm.loadAddrRegion = row.addrRegion || '';
        this.localForm.loadAddrDetail = row.addrDetail || '';
        this.localForm.loadAddrPoint = row.addrPoint || '';
        this.localForm.extMap.loadCustUserName = row.custUserName;
        this.localForm.extMap.loadCustUserPhone = row.custUserPhone;
      } else {
        //  "unloadAddrRegion": "卸货三级地址2",
        // "unloadAddrDetail": "卸货详细地址2",
        // "unloadAddrPoint": "卸货经纬度2",
        this.localForm.unloadAddrRegion = row.addrRegion || '';
        this.localForm.unloadAddrDetail = row.addrDetail || '';
        this.localForm.unloadAddrPoint = row.addrPoint || '';
        this.localForm.extMap.unloadCustUserName = row.custUserName;
        this.localForm.extMap.unloadCustUserPhone = row.custUserPhone;
      }
      this.$emit('addressChange', type, id, row);
    },
    refreshMap(AMap) {
      this.marker = new AMap.Marker();
      this.AMap = AMap;
    },
    durationBlur(e, type) {
      this.durationInit += 1;
      if (type === 'hour') {
        this.localForm.duration = +(this.localForm.minDuration || 0) * 60 + +e.target.value * 60 * 60;
      } else {
        this.localForm.duration = +(this.localForm.hourDuration || 0) * 60 * 60 + +e.target.value * 60;
      }
    },
    addMarker() {
      this.$eventBus.$emit('update-flag');
    },
    drivingResultChange(result) {
      if (!result.autoFill || this.mode === 'detail') {
        return;
      }
      const durationHour = this.formatSeconds2(+result.time);
      const hour = durationHour[0];
      const minute = durationHour[1];
      this.localForm.distance = result.distance ? (Math.round((+result.distance / 1000) * 100) / 100).toFixed(2) : undefined;
      this.localForm.hourDuration = hour;
      this.localForm.minDuration = minute;
      this.localForm.duration = (hour || 0) * 60 * 60 + (minute || 0) * 60;
    },
    formatSeconds2(value) {
      let theTime = parseInt(value); // 秒

      let middle; // 分
      let hour; // 小时
      if (theTime >= 60) {
        middle = (Math.ceil((theTime / 60) * 100) / 100).toFixed(0);
        theTime = parseFloat(theTime % 60);
        if (middle >= 60) {
          hour = parseInt(middle / 60);
          middle = (Math.ceil((middle % 60) * 100) / 100).toFixed(0);
        }
      }
      return [hour, middle, theTime];
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const needForm = {
            id: this.localForm.id || '',
            lineName: this.localForm.startName + '-' + this.localForm.endName, // ''线路名称2',
            productId: this.localForm.productId, // ' 112,
            productName: this.localForm.productName, // ' '产品名称2',
            loadAddrId: this.localForm.loadAddrId, // ' 112,
            loadAddrRegion: this.localForm.loadAddrRegion, // ' '装货三级地址2',
            loadAddrDetail: this.localForm.loadAddrDetail, // ' '装货详细地址2',
            loadAddrPoint: this.localForm.loadAddrPoint, // ' '装货经纬度2',
            unloadAddrId: this.localForm.unloadAddrId, // ' 112,
            unloadAddrRegion: this.localForm.unloadAddrRegion, // ' '卸货三级地址2',
            unloadAddrDetail: this.localForm.unloadAddrDetail, // ' '卸货详细地址2',
            unloadAddrPoint: this.localForm.unloadAddrPoint, // ' '卸货经纬度2',
            distance: +this.localForm.distance, // ' 112, // 里程数
            duration: this.localForm.duration, // ' 112, // 时长
            remark: this.localForm.remark || '-', // ' '备注2',
            extMap: {
              price: this.localForm.extMap.price, // ' '运价2',
              needTax: this.localForm.extMap.needTax, // ' '是否含税2',
              priceUnit: +this.localForm.extMap.priceUnit || '',
              loadCustUserName: this.localForm.extMap.loadCustUserName,
              loadCustUserPhone: this.localForm.extMap.loadCustUserPhone,
              unloadCustUserName: this.localForm.extMap.unloadCustUserName,
              unloadCustUserPhone: this.localForm.extMap.unloadCustUserPhone,
              loadAddressName: this.localForm.startName,
              unloadAddressName: this.localForm.endName,
            },
            ext1: this.localForm.ext1,
            ext1Name: this.localForm.ext1Name,
            ...this.companyInfo,
          };
          if (!needForm.id) {
            delete needForm.id;
          }
          this.$emit('resultChange', needForm);
        }
      });
    },
    minuteLimit(keyword) {
      const reg = /^[1-9]\d*$/;
      // 如果不符合正则，提示
      const index = this.localForm[keyword].indexOf('.');
      if (index !== -1) {
        this.localForm[keyword] = this.localForm[keyword].slice(0, index);
      }
      if (!reg.test(+this.localForm[keyword])) {
        this.localForm[keyword] = '';
      }
      if (+this.localForm[keyword] >= 60) {
        this.localForm[keyword] = 59;
      }
    },
    numberLimit(keyword, integer, decimal) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.localForm[keyword])) {
        this.localForm[keyword] = '';
      }
      const amount = this.localForm[keyword];
      // 保留小数
      const pArr = amount.split('.');
      pArr[0] = pArr[0].substr(0, +integer);
      if (pArr.length > 1 && decimal !== 0) {
        this.localForm[keyword] = pArr[0] + '.' + pArr[1].substr(0, +decimal);
      } else {
        this.localForm[keyword] = pArr[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.course-form-wrap {
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(90, 96, 115, 0.14);
  background: #fff;
  width: 400px;
  padding: 20px 20px 20px 15px;
  .absolute{
    position: absolute;
  }
  .relative{
    position: relative;
  }
  .address-icon{
    height: 26px;
    position: absolute;
    left: -50px;
  }
  .contacts{
    line-height: 19px;
    color: $grey;
  }
  .duration-input {
    display: flex;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .duration-input-unit {
      text-align: center;
      width: 90px;
      line-height: 36px;
    }
    ::v-deep {
      .el-input__inner {
        border: none;
      }
    }
  }
  .course-form-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: #26272a;
    line-height: 24px;
  }
  .form {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 280px);
    margin-right: -15px;
    padding-right: 20px;
  }
  .form-item {
    position: relative;
    .duration-input-tip {
      color: #ff4d4f;
      font-size: 12px;
      position: absolute;
      bottom: -28px;
      animation: bottom 0.2s ease;
    }
  }
  .line-name{
    position: relative;
    .iconfont{
      position: absolute;
      left: 60%;
      top: 10px;
      color: #4385ff;
    }
  }
.end-line{
  ::v-deep{
    .el-form-item__content{
      margin-left: 30px!important;
    }
  }
}
  .unit {
    z-index: 1;
    right: 10px;
    color: #5a6073;
  }
  .unit-img {
    color: #4385ff;
    font-size: 18px;
    z-index: 1;
    right: 10px;
    top: 6px;
    cursor: pointer;
  }
  .add-mark {
    margin-top: 7px;
    height: 20px;
    font-weight: 600;
    color: #4385ff;
    line-height: 20px;
    cursor: pointer;
  }
  .has-select{
    margin-bottom: 3px;
  }
}
</style>
